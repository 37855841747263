import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { getColorClass, StyledPlayerDataGrid } from "../common/StyledPlayerTable";


const MinutesPerUnitPrice = (props) => {
    const [tableData, setTableData] = useState([]);
    const columns = [
        { field: "used_name", headerName: "Used Name", minWidth: 150},
        { field: "first_name", headerName: "First Name", minWidth: 150},
        { field: "second_name", headerName: "Second Name", minWidth: 200},
        { field: "team", headerName: "Team", minWidth: 100},
        { field: "position", headerName: "Position", minWidth: 100},
        { field: "minutes", headerName: "Minutes Played ", minWidth: 100},
        { field: "now_cost", headerName: "Now Cost", minWidth: 100},
        { field: "form_rank", headerName: "Form Rank", minWidth: 100},
        { field: "form_rank_type", headerName: "Form Rank in Position", minWidth: 150},
        { field: "minutes_per_unit_price", headerName: "Minutes Per Unit Price", minWidth: 150},
    ];
    useEffect(() => {
        if (props.players && props.teams && props.playerTypes) {
            const playersInfo = props.players.map(player => ({
                id: player.id,
                used_name: player.web_name,
                first_name: player.first_name,
                second_name: player.second_name,
                team_code: player.team,
                team: props.teams.find(team => team.id === player.team)?.name || "Unknown",
                position: props.playerTypes.find(type => type.id === player.element_type)?.singular_name || "Unknown",
                now_cost: Number(player.now_cost/10).toFixed(1),
                minutes: player.minutes,
                form_rank: player.form_rank,
                form_rank_type: player.form_rank_type,
                minutes_per_unit_price: Number(player.minutes/player.now_cost).toFixed(1),
            }));
            setTableData(playersInfo);
        }
    }, [props.players, props.teams, props.playerTypes]);

    return (
        <Box
            sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderRadius: 2,
                p: 2,
                mx: "auto",
                my: 2,
            }}
        >
            <Typography 
                variant="h4"
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    mb: 2
                }}
            > Player Minutes: Find out who is likely to play more minutes per unit price!</Typography>
            <StyledPlayerDataGrid
                getRowClassName={getColorClass}
                sx={{
                 width: "90%",
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                    sorting: {
                        sortModel: [{ field: 'minutes_per_unit_price', sort: 'desc' }],
                    },
                }}
                rows={tableData} 
                columns={columns}
                disableColumnSorting={true}
            />
        </Box>
    );
}

export default MinutesPerUnitPrice;