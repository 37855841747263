const teamsColorScaleDictionary = {
  1: "#D80919",
  2: "#95BFE5",
  3: "#D41E2A",
  4: "#FD0000",
  5: "#0A56A3",
  6: "#034694",
  7: "#1B458F",
  8: "#063A99",
  9: "#FFFFFF",
  10: "#005B9E",
  11: "#1358A1",
  12: "#E0202C",
  13: "#99C5E7",
  14: "#DC1F29",
  15: "#000000",
  16: "#DC0202",
  17: "#D41E29",
  18: "#11214B",
  19: "#7A263A",
  20: "#FC891C",
};

const getTeamColor = (teamId) => {
  return teamsColorScaleDictionary[teamId];
};

const getTeamColorsList = () => {
  return Object.values(teamsColorScaleDictionary);
};

export { getTeamColor, getTeamColorsList, teamsColorScaleDictionary };
