import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { getColorClass, StyledPlayerDataGrid } from "../common/StyledPlayerTable";

const PriceToRise = (props) => {
    const [tableData, setTableData] = useState([]);
    const columns = [
        { field: "used_name", headerName: "Used Name", minWidth: 150},
        { field: "first_name", headerName: "First Name", minWidth: 150},
        { field: "second_name", headerName: "Second Name", minWidth: 200},
        { field: "team", headerName: "Team", minWidth: 100},
        { field: "position", headerName: "Position", minWidth: 100},
        { field: "transfers_in_event", headerName: "Transfers In", minWidth: 100},
        { field: "transfers_out_event", headerName: "Transfers Out", minWidth: 100},
        { field: "transfers_difference", headerName: "Transfers Difference", minWidth: 100},
        { field: "now_cost", headerName: "Now Cost", minWidth: 100},
        { field: "cost_change_event", headerName: "Cost Change", minWidth: 100},
    ];
    useEffect(() => {
        if (props.players && props.teams && props.playerTypes) {
            const playersInfo = props.players.map(player => ({
                id: player.id,
                used_name: player.web_name,
                first_name: player.first_name,
                second_name: player.second_name,
                team_code: player.team,
                team: props.teams.find(team => team.id === player.team)?.name || "Unknown",
                position: props.playerTypes.find(type => type.id === player.element_type)?.singular_name || "Unknown",
                transfers_in_event: player.transfers_in_event,
                transfers_out_event: player.transfers_out_event,
                transfers_difference: player.transfers_in_event - player.transfers_out_event,
                now_cost: Number(player.now_cost/10).toFixed(1),
                cost_change_event: Number(player.cost_change_event/10).toFixed(1)
            }));
            setTableData(playersInfo);
        }
    }, [props.players, props.teams, props.playerTypes]);

    return (
        <Box
            sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderRadius: 2,
                p: 2,
                mx: "auto",
                my: 2,
            }}
        >
            <Typography 
                variant="h4"
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    mb: 2
                }}
            > Player Price: Find out whose price is likely to rise!</Typography>
            <StyledPlayerDataGrid
                getRowClassName={getColorClass}
                sx={{
                 width: "90%",
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                    sorting: {
                        sortModel: [{ field: 'transfers_difference', sort: 'desc' }],
                    },
                }}
                rows={tableData} 
                columns={columns}
                disableColumnSorting={true}
            />
        </Box>
    );
}

export default PriceToRise;