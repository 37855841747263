import React from "react";
import { Box, Typography } from "@mui/material";

// Utilities
import numberWithCommas from "../../utilities/numberWithCommas";

const TotalPlayers = ({totalPlayers}) => {
    return (
        <Box
            sx={{
                display: "block",
                width: "33%",
                textAlign: "center",
                mb: 2
            }}
        >
            <Typography>Current FPL Managers: {numberWithCommas(totalPlayers)} </Typography>
        </Box>
    );
};

export default TotalPlayers;
