import React, { useState, useEffect } from "react";

// MUI Component
import { Grid2 } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { ScatterChart } from "@mui/x-charts";

// Local Components
import TotalPlayers from "../common/totalplayers";

// Utilities
import { getTeamColor } from "../../utilities/teamColors";
import numberToOneDecimal from "../../utilities/numberToOneDecimal";

const PointsAgainstPrice = (props) => {
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedPlayerType, setSelectedPlayerType] = useState(null);


// Filter players based on selected team and player type
const filteredPlayers = props.players ? props.players.filter(player => {
    const teamMatch = selectedTeam && selectedTeam.name !== "All" ? player.team === selectedTeam.id : true;
    const typeMatch = selectedPlayerType && selectedPlayerType.singular_name !== "All" ? player.element_type === selectedPlayerType.id : true;
    return teamMatch && typeMatch;
}) : [];

// Group filtered players by team
const groupedPlayers = filteredPlayers.reduce((acc, player) => {
    const teamName = props.teams.find(team => team.id === player.team)?.name || "Unknown";
    if (!acc[teamName]) {
        acc[teamName] = [];
    }
    acc[teamName].push({
        y: player.now_cost / 10,
        x: player.total_points,
        first_name: player.first_name,
        second_name: player.second_name,
        cost_change_start: player.cost_change_start,
        now_cost: player.now_cost,
        web_name: player.web_name,
        total_points: player.total_points,
        selected_by_percent: player.selected_by_percent,
        form: player.form,
        position: player.element_type === 1 ? "Goalkeeper" : player.element_type === 2 ? "Defender" : player.element_type === 3 ? "Midfielder" : player.element_type === 4 ? "Forward" : "Unknown",
        team_name: teamName,
    });
    return acc;
    }, {});

    // Transform grouped players into series format
    const series = Object.keys(groupedPlayers).map((teamName) => ({
        label: teamName,
        data: groupedPlayers[teamName],
        color: getTeamColor(props.teams.find(team => team.name === teamName)?.id),
        highlightScope: {
            highlighted: "item",
            faded: "global",
        }
    }));

    // Custom tooltip renderer
    const renderTooltip = (props) => {
        const dataIndex = props.itemData.dataIndex;
        const point = props.series.data[dataIndex];
        return (
            <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid black' }}>
                <div><strong>Name:</strong> {point.web_name}</div>
                <div><strong>First Name:</strong> {point.first_name}</div>
                <div><strong>Second Name:</strong> {point.second_name}</div>
                <div><strong>Position:</strong> {point.position}</div>
                <div><strong>Now Cost:</strong> {numberToOneDecimal(Number(point.now_cost / 10))}</div>
                <div><strong>Cost Change from Start:</strong> {numberToOneDecimal(Number(point.cost_change_start/10))}</div>
                <div><strong>Selected By Percent:</strong> {numberToOneDecimal(Number(point.selected_by_percent))}%</div>
                <div><strong>Team:</strong> {point.team_name}</div>
                <div><strong>Form:</strong> {point.form}</div>
                <div><strong>Total Points:</strong> {Number(point.total_points)}</div>
            </div>
        );
    };

    return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    width: "80%",
                    mx: "auto",
                    my: 2,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                {props.totalFplManagers && (
                    <TotalPlayers totalPlayers={props.totalFplManagers} />
                )}
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        mb: 2
                    }}
                >Player Cost Analysis: Find out how players perform based on their cost!</Typography>
            
            <Grid2 container width="100%">
                <Grid2 
                    xs={12} 
                    md={6} 
                    width={{xs: "100%", md: "50%"}}
                >
                    {props.teams && (
                        <Autocomplete
                            options={props.teams}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => setSelectedTeam(newValue)}
                            renderInput={(params) => <TextField {...params} label="Team"
                            sx={{
                                width:{xs: "80%", md: "50%"},
                                mx: {xs: "10%", md: "25%"},
                                my: {xs: 0.5, md: 0}
                            }}
                            />}
                        />
                    )}
                </Grid2>
                <Grid2 
                    xs={12} 
                    md={6} 
                    width={{xs: "100%", md: "50%"}}
                >
                    {props.playerTypes && (
                        <Autocomplete
                            options={props.playerTypes}
                            getOptionLabel={(option) => option.singular_name}
                            onChange={(event, newValue) => setSelectedPlayerType(newValue)}
                            renderInput={(params) => <TextField {...params} label="Player Type"
                            sx={{
                                width:{xs: "80%", md: "50%"},
                                mx: {xs: "10%", md: "25%"},
                                my: {xs: 0.5, md: 0}
                            }} 
                            />}
                        />
                    )}
                </Grid2>
            </Grid2>
            {props.players && (
                <Box
                    sx={{
                        width: "100%",
                        height: "600px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        mt: 0
                    }}
                >
                    <Box
                        sx={{
                            width: "80%",
                            height: "600px",
                        }}
                    >
                    <ScatterChart
                        voronoiMaxRadius={20}
                        axisHighlight={{x: "line", y: "line" }}
                        grid={{ vertical: true, horizontal: true }}
                        series={ series }
                        tooltip={{ trigger: "item", itemContent: renderTooltip }}
                        leftAxis={{
                            label: "Player Cost",
                            valueFormatter: (value) => `${value}`,
                            tickCount: 10,
                            tickFormat: (value) => `${value}`,
                            tickLine: true,
                            tickSize: 10,
                            tickWidth: 1,
                        }}
                        bottomAxis={{
                            label: "Total Points",
                            valueFormatter: (value) => `${value}`,
                            tickCount: 10,
                            tickFormat: (value) => `${value}`,
                            tickLine: true,
                            tickSize: 10,
                            tickWidth: 1,
                        }}
                        slots={{
                            scatter: (group) => {
                                const { xScale, yScale, color, series } = group;
                                const data = series.data;
                                return data.map((point, index) => (
                                    <circle
                                        key={index}
                                        cx={xScale(point.x)}
                                        cy={yScale(point.y)}
                                        r={5}
                                        fill={color}
                                        stroke="black"
                                        strokeWidth="0.5"
                                    />
                                ));
                            },
                        }}
                        slotProps={{
                            legend: {
                                hidden: true
                            },
                        }}
                    />
                    </Box>
                </Box>
            )}
            </Box>
    );
};

export default PointsAgainstPrice;