import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { getColorClass, StyledPlayerDataGrid } from "../common/StyledPlayerTable";

const PointsPerUnitPrice = (props) => {
    const [tableData, setTableData] = useState([]);
    const columns = [
        { field: "used_name", headerName: "Used Name", minWidth: 150},
        { field: "first_name", headerName: "First Name", minWidth: 150},
        { field: "second_name", headerName: "Second Name", minWidth: 200},
        { field: "team", headerName: "Team", minWidth: 100},
        { field: "position", headerName: "Position"},
        { field: "now_cost", headerName: "Cost"},
        { field: "selected_by_percent", headerName: "Selected By (%)", minWidth: 150},
        { field: "total_points", headerName: "Total Points"},
        { field: "points_per_unit_price", headerName: "Points Per Unit Price", minWidth: 150 },
    ];
    useEffect(() => {
        if (props.players && props.teams && props.playerTypes) {
            const pointsPerUnitPrice = props.players.map(player => ({
                id: player.id,
                used_name: player.web_name,
                first_name: player.first_name,
                second_name: player.second_name,
                team_code: player.team,
                team: props.teams.find(team => team.id === player.team).name,
                position: props.playerTypes.find(type => type.id === player.element_type).singular_name,
                selected_by_percent: Number(player.selected_by_percent).toFixed(1),
                now_cost: Number(player.now_cost/10).toFixed(1),
                total_points: Number(player.total_points),
                points_per_unit_price: Number((Number(player.total_points) / Number(player.now_cost/10)).toFixed(3))
            }));
            setTableData(pointsPerUnitPrice)
        }
    }, [props.players, props.teams, props.playerTypes]);

    return (
        <Box
            sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderRadius: 2,
                p: 2,
                mx: "auto",
                my: 2,
            }}
        >
            <Typography 
                variant="h4"
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    mb: 2
                }}
            > Player Performance: Find out how many points per unit price you get!</Typography>
            <StyledPlayerDataGrid
                sx={{
                    width: {xs: "100%", md: "90%"},
                    cursor: "pointer"
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                    sorting: {
                        sortModel: [{ field: 'points_per_unit_price', sort: 'desc' }],
                    },
                }}
                rows={tableData} 
                columns={columns}
                getRowClassName={getColorClass}
            />
        </Box>
    );
};

export default PointsPerUnitPrice;