import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import { styled, lighten } from "@mui/material/styles";
import { teamsColorScaleDictionary } from "../../utilities/teamColors";

const getColorClass = (params) => {
    return `team-${params.row.team_code}`;
};

const myDataGrid = (props) => (
    <DataGrid
        {...props}
        density="compact"
        disableColumnSorting={true}
        disableDensitySelector={true}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
          },
        }}
    />
);

const StyledPlayerDataGrid = styled(myDataGrid) (()=>{
    return {
        poniter: "cursor",
        borderRight: "none",
        borderLeft: "none",
        "& .MuiDataGrid-topContainer": {
            borderLeft: 1,
            borderRight: 1,
        },
        "& .MuiDataGrid-row": {
            cursor: "pointer"
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
        },
        [`& .team-${1}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[1], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[1], 0.3)
            }
        },
        [`& .team-${2}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[2], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[2], 0.3)
            }
        },
        [`& .team-${3}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[3], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[3], 0.3)
            }
        },
        [`& .team-${4}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[4], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[4], 0.3)
            }
        },
        [`& .team-${5}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[5], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[5], 0.3)
            }
        },
        [`& .team-${6}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[6], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[6], 0.3)
            }
        },
        [`& .team-${7}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[7], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[7], 0.3)
            }
        },
        [`& .team-${8}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[8], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[8], 0.3)
            }
        },
        [`& .team-${9}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[9], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[9], 0.3)
            }
        },
        [`& .team-${10}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[10], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[10], 0.3)
            }
        },
        [`& .team-${11}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[11], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[11], 0.3)
            }
        },
        [`& .team-${12}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[12], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[12], 0.3)
            }
        },
        [`& .team-${13}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[13], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[13], 0.3)
            }
        },
        [`& .team-${14}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[14], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[14], 0.3)
            }
        },
        [`& .team-${15}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[15], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[15], 0.3)
            }
        },
        [`& .team-${16}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[16], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[16], 0.3)
            }
        },
        [`& .team-${17}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[17], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[17], 0.3)
            }
        },
        [`& .team-${18}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[18], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[18], 0.3)
            }
        },
        [`& .team-${19}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[19], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[19], 0.3)
            }
        },
        [`& .team-${20}`]: {
            backgroundColor: lighten(teamsColorScaleDictionary[20], 0.5),
            "&:hover": {
                backgroundColor: lighten(teamsColorScaleDictionary[20], 0.3)
            }
        },
    }
});

export  {getColorClass, StyledPlayerDataGrid};
