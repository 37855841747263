import React, { useState, useEffect } from "react";
import { 
    PointsPerUnitPrice, 
    PriceToRise, 
    PriceToFall, 
    PointsAgainstPrice,
    MinutesPerUnitPrice
} from "../components/priceanalysis";

const PriceAnalysis = (props) => {

    return (
        <div>
            <PointsAgainstPrice
                teams={props.teams}
                players={props.players}
                playerTypes={props.playerTypes}
                totalFplManagers={props.totalFplManagers}
            />
            <PointsPerUnitPrice 
                teams={props.teams}
                players={props.players}
                playerTypes={props.playerTypes}
            />
            <PriceToRise
                teams={props.teams}
                players={props.players}
                playerTypes={props.playerTypes}
            />
            <PriceToFall
                teams={props.teams}
                players={props.players}
                playerTypes={props.playerTypes}
            />
            <MinutesPerUnitPrice
                teams={props.teams}
                players={props.players}
                playerTypes={props.playerTypes}
            />
        </div>
    );
};

export default PriceAnalysis;
