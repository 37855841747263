import React from "react";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import PriceAnalysis from "./dashboards/PriceAnalysis";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { useState, useEffect } from "react";
import Loading from "./components/common/Loading";
import { requestGeneralData } from "./requests/requestGeneralData";

function App() {
  const [teams, setTeams] = useState(null);
  const [players, setPlayers] = useState(null);
  const [playerTypes, setPlayerTypes] = useState(null);
  const [totalFplManagers, setTotalFplManagers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const data = await requestGeneralData();
      const teams = data.teams ? data.teams : [];
      const players = data.elements ? data.elements : [];
      const playerTypes = data.element_types ? data.element_types : [];
      const totalFplManagers = data.total_players ? data.total_players : 0;
      setTeams(teams);
      setPlayers(players);
      setPlayerTypes(playerTypes);
      setTotalFplManagers(totalFplManagers);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100vw",
        }}
      >
        {isLoading ? (
          <React.Fragment key="loading">
            <Loading />
          </React.Fragment>
        ) : (
          <React.Fragment key="content">
            <Header />
            <PriceAnalysis
              teams={teams}
              players={players}
              playerTypes={playerTypes}
              totalFplManagers={totalFplManagers}
            />
            <Footer />
          </React.Fragment>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
