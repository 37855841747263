import React from "react";
import { Box, Typography } from "@mui/material";
import { ClimbingBoxLoader } from "react-spinners";

const Loading = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    width: "25%",
                    height: "25%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ClimbingBoxLoader />
                <Typography>Loading...</Typography>
            </Box>
        </Box>
    );
};

export default Loading;
