import React from "react";
import { Box, Link, Grid2, Typography } from "@mui/material";
import CoffeeIcon from '@mui/icons-material/Coffee';
import FPLGuessLogo from "../../images/FPLGuessLogo.png";
import XIcon from '@mui/icons-material/X';

const Footer = () => {
    return (
        <Box sx={{
            backgroundColor: "white",
            width: "80%",
            borderRadius: 2,
            p: 2,
            mx: "auto",
            my: 2,
        }}>
        <Grid2 
        container
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
        }}>
            <Grid2 xs={12} md={4}>
                <img src={FPLGuessLogo} alt="FPLGuess" style={{ width: "25px" }} />
            </Grid2>
            <Grid2 xs={12} md={4}>
            <Link href="https://www.buymeacoffee.com/hammy25" target="_blank" rel="noopener">
            <Typography
                sx={{
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <CoffeeIcon
                    sx={{
                        fontSize: 14,
                        mr: 0.5,
                    }}
                />
                Buy me a coffee
            </Typography>
        </Link>
                
            </Grid2>
            <Grid2 xs={12} md={4}>
                <Typography
                    sx={{
                        fontSize: 14,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                > Made with ❤️ by  
                <Link 
                    href="https://twitter.com/h_mwawuda" 
                    underline="hover"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                <XIcon 
                sx={{ 
                    fontSize: 14,
                    ml: 0.5,
                    mr: 0.5
                }} 
                /> h_mwawuda</Link>
                </Typography>
            </Grid2>
            </Grid2>
        </Box>
    );
}

export default Footer;
